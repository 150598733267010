import React, { useState } from 'react'; // Import useState from React
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '../Registration/Registration.css'; // Import CSS for the registration component
import { Link } from 'react-router-dom'; // Import Link for navigation
const logo = 'https://shilohbridal.pmpframe.com/api/upload/logo.svg'; // Import logo image

export default function Registration() {
  // Declare state variables
  const [emailOrMobile, setEmailOrMobile] = useState(""); // For email or mobile input
  const [fullname, setFullName] = useState(""); // For full name input
  const [password, setPassword] = useState(""); // For password input
  const [confirm_password, setConfirmPassword] = useState(""); // For confirm password input
  const [error, setError] = useState(""); // For error messages
  const navigate = useNavigate(); // For navigation after registration

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Password validation
    if (password !== confirm_password) {
      setError("Passwords do not match.");
      return;
    }

    // Check if the input is either a valid email or mobile number
    const isEmail = /\S+@\S+\.\S+/.test(emailOrMobile);
    const isMobile = /^\d+$/.test(emailOrMobile);
  
    if (!isEmail && !isMobile) {
      setError("Please enter a valid email or mobile number.");
      return;
    }

    // Prepare the form data
    const formData = new FormData();
    formData.append('fullname', fullname);
    formData.append(isEmail ? 'email' : 'mobile', emailOrMobile);
    formData.append('password', password);
    formData.append('confirm_password', confirm_password);

    // API call to register the user
    fetch('https://shilohbridal.pmpframe.com/api/registration.php', {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert("Registration successful!");
          navigate('/Singin');
        } else {
          setError(data.message || 'Something went wrong.');
        }
      })
      .catch(error => {
        setError('Failed to register. Please try again later.');
        console.error('Error:', error);
      });
  };

  return (
    <div className="container-registration">
      <div className="row">
        <div className="col-lg-12 d-flex align-items-center justify-content-flex-end ">
          <div className="form-2-wrapper-registration">
            <div className="logo text-center">
              <img src={logo} className="logo-image" alt="Logo" />
            </div>
            <h2 className="text-center mb-4">Sign Up</h2>

            {/* Show error message if any */}
            {error && <div className="alert alert-danger">{error}</div>}

            <form onSubmit={handleSubmit}>
              <div className="mb-3 form-box">
                <input
                  type="text" // Full name input type
                  className="form-control"
                  id="fullname"
                  name="fullname"
                  placeholder="Enter Your Full Name"
                  value={fullname}
                  onChange={(e) => setFullName(e.target.value)} // Handle full name input
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="text" // Email or mobile input type
                  className="form-control"
                  id="emailOrMobile"
                  name="emailOrMobile"
                  placeholder="Enter Your Email or Mobile No."
                  value={emailOrMobile}
                  onChange={(e) => setEmailOrMobile(e.target.value)} // Handle email or mobile input
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password" // Password input type
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // Handle password input
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password" // Confirm password input type
                  className="form-control"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirm_password}
                  onChange={(e) => setConfirmPassword(e.target.value)} // Handle confirm password input
                  required
                />
              </div>
              
              <button
                type="submit"
                className="btn btn-outline-secondary login-btn w-100 mb-3"
              >
                Sign Up
              </button>
            </form>

            <p className="text-center register-test mt-3">
              Already have an account?{' '}
              <Link to="/signin" className="text-decoration-none">
                Sign In
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
