import React, { useState } from 'react';
import './Collection.css';

// MainImage Component
const MainImage = ({ src, alt }) => {
  return (
    <div className="main-image">
      <img src={src} alt={alt} />
    </div>
  );
};

// Thumbnail Component
const Thumbnail = ({ src, alt, onClick }) => {
  return <img className="thumbnail-image" src={src} alt={alt} onClick={onClick} />;
};

// Collection Component
const Collection = ({ title, mainImageSrc, mainImageAlt, thumbnails, buttonText }) => {
  const [selectedImage, setSelectedImage] = useState(mainImageSrc);

  return (
    <div className="bridal-collection">
      <h2 className="bridal-collection-title">{title}</h2>
      <div className="bridal-collection-container">
        {/* Left side: Larger main image */}
        <div className="left-side">
          <MainImage src={selectedImage} alt={mainImageAlt} />
        </div>

        {/* Right side: 4 smaller thumbnails */}
        <div className="right-side">
          <div className="thumbnail-grid">
            {thumbnails.map((thumbnail, index) => (
              <Thumbnail
                key={index}
                src={thumbnail.src}
                alt={thumbnail.alt}
                onClick={() => setSelectedImage(thumbnail.src)}
              />
            ))}
          </div>
          <button className="view-all-button">{buttonText}</button>
        </div>
      </div>
    </div>
  );
};

// App Component
const App = () => {
  // Define the image URLs directly
  const bridalvideo = 'https://shilohbridal.pmpframe.com/api/upload/bridalvideo.svg';
  const thumbnails = [
    { src: 'https://shilohbridal.pmpframe.com/api/upload/Img3.svg', alt: 'Bridal Dress 1' },
    { src: 'https://shilohbridal.pmpframe.com/api/upload/img4.svg', alt: 'Bridal Dress 2' },
    { src: 'https://shilohbridal.pmpframe.com/api/upload/Img5.svg', alt: 'Bridal Dress 3' },
    { src: 'https://shilohbridal.pmpframe.com/api/upload/Img6.svg', alt: 'Bridal Dress 4' },
  ];

  return (
    <Collection
      title="Bridal Collection"
      mainImageSrc={bridalvideo}
      mainImageAlt="Bridal Dress"
      thumbnails={thumbnails}
      buttonText="View All"
    />
  );
};

export default App;
