import React from "react";
import "./NavHeading.css";

export default function NavHeading() {
  return (
    <div className="Navbar">
      <h2>Shipping Worldwide</h2>
    </div>
  );
}
