import React, { useState } from "react";
import "../Login/Login.css";
import { FaFacebook, FaGoogle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const [emailOrMobile, setEmailOrMobile] = useState(""); // Updated input to be either email or mobile
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  
  const logo = "https://shilohbridal.pmpframe.com/api/upload/logo.svg";

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Submitting form...");

    // Determine if input is email or mobile number
    const isEmail = emailOrMobile.includes("@"); // Simple check to see if it's an email

    const formData = new FormData();
    formData.append(isEmail ? "email" : "mobile", emailOrMobile); // If it's an email, append "email", otherwise append "mobile"
    formData.append("password", password);

    try {
      const response = await fetch("https://shilohbridal.pmpframe.com/api/login.php", {
        method: "POST",
        body: formData, // Send form data
      });

      // Check if the response is JSON (login success or failure)
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        console.log("Response received:", data);

        if (data.success) {
          alert("Login successful!");
          navigate("/Account"); // Redirect to account page on success
        } else {
          setError(data.message || "Login failed. Please check your credentials.");
        }
      } else {
        // Handle case if server returns non-JSON (like HTML error page)
        const errorText = await response.text();
        console.error("Error response:", errorText);
        setError("Server error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="container-login">
      <div className="row">
        <div className="col-lg-12 align-items-center justify-content-end">
          <div className="form-2-wrapper">
            <div className="logo text-center">
              <img src={logo} className="logo-image" alt="Shiloh Bridal Logo" />
            </div>
            <h2 className="text-center mb-4">Sign In</h2>
            {error && <p className="text-danger text-center">{error}</p>}
            <form onSubmit={handleSubmit}>
              <div className="mb-3 form-box">
                <input
                  type="text" // Changed to text to accept both email and mobile
                  className="form-control"
                  id="emailOrMobile"
                  name="emailOrMobile"
                  placeholder="Enter Your Email OR Mobile No."
                  value={emailOrMobile}
                  onChange={(e) => setEmailOrMobile(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="Enter Your Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMe"
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Remember me
                  </label>
                  <Link
                    to="/ForgotPassword"
                    className="text-decoration-none float-end"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline-secondary login-btn w-100 mb-3"
              >
                Sign In
              </button>
              <div className="social-login mb-3 type--A">
                <h5 className="text-center mb-2">OR</h5>
                <Link to="/" className="icon" style={{ marginLeft: "190px" }}>
                  <FaGoogle />
                </Link>
                <Link to="/" className="icon">
                  <FaFacebook />
                </Link>
              </div>
            </form>

            <p className="text-center register-test mt-3">
              Don't have an account?{" "}
              <Link to="/Signup" className="text-decoration-none">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
