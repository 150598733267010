import React from 'react';
import '../Blog/Blog.css';

// Blog data as an array of objects
const blogData = [
  {
    id: 1,
    img: 'https://shilohbridal.pmpframe.com/api/upload/blog1.svg',
    date: 'June 24, 2024',
    text: 'How to Accessorize Your Wedding Dress: Veils, Shoes, and Jewelry Tips',
  },
  {
    id: 2,
    img: 'https://shilohbridal.pmpframe.com/api/upload/blog2.svg',
    date: 'Aug 26, 2024',
    text: 'A Guide to Bridesmaid Dresses: Coordinating Styles and Colors',
  },
  // Add more blog objects here if needed
];

export default function Blog() {
  return (
    <div className="container-blog">
      <h2>Our Blog</h2>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {blogData.map((blog) => (
          <div className="col-blog" key={blog.id}>
            <div className="card">
              <img src={blog.img} className="card-img-top" alt={`Blog ${blog.id}`} />
              <div className="card-body">
                <p className="card-date">{blog.date}</p>
                <p className="card-text">{blog.text}</p>
                <button type="button" className="btn-blog">Read Full Blog</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Centered View All button with an arrow */}
      <div className="view-all-container">
        <button type="button" className="btn-view-all">
          View All <span className="arrow-right">→</span>
        </button>
      </div>
    </div>
  );
}
