import React from 'react';
import './Newsletter.css'; 
const  newsletter = 'https://shilohbridal.pmpframe.com/api/upload/newsletter.svg';

const Newsletter = () => {
  return (
    <div className="newsletter-card">
      <div className="row">
      <div className="newsletter-content col-6">
        <h2>Subscribe to Our Newsletter</h2>
        
        <input type="email" placeholder="Enter your email" />
        <p>Join Our Bridal Community for Special Updates and Style Guides</p>
        <button>Subscribe</button>
      </div>    
      <div className="newsletter-image col-6">
        <img src={newsletter} alt="Newsletter" />
      </div>
      </div>
    </div>
  );
}

export default Newsletter;
