import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// import files
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import ShopByCategories from './pages/ShopByCategories';
import Product from './pages/Product';
import MyCart from './pages/MyCart';
import Singin from './pages/Singin';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import OTP from './components/ForgotPassword/OTP';
import NewPassword from './components/ForgotPassword/NewPassword';
import Wishlist from './components/Wishlist/Wishlist';
// import Account from './pages/Account';

function App() {
  return (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/ShopByCategories" element={<ShopByCategories />} />
      <Route path="/Product" element={<Product />} />
      <Route path="/MyCart" element={<MyCart />} />
      <Route path="/Singin" element={<Singin />} />
      <Route path="/Signup" element={<Signup />} />
      <Route path="/ForgotPassword" element={<ForgotPassword />} />
      <Route path="/OTP" element={<OTP />} />
      <Route path="/NewPassword" element={<NewPassword />} />
      <Route path="/Wishlist" element={<Wishlist />} />
      {/* <Route path="/Account" element={<Account />} /> */}
     </Routes>
    </Router>
  );
}

export default App; 



