import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";
import "./ClotheMenu.css";

// Silhouette Images
const s1 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s1.svg";
const s2 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s2.svg";
const s3 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s3.svg";
const s4 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s4.svg";
const s5 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s5.svg";
const s6 = "https://shilohbridal.pmpframe.com/api/upload/Silhouette/s6.svg";

// Train Images
const t1 = "https://shilohbridal.pmpframe.com/api/upload/Train/t1.svg";
const t2 = "https://shilohbridal.pmpframe.com/api/upload/Train/t2.svg";
const t3 = "https://shilohbridal.pmpframe.com/api/upload/Train/t3.svg";
const t4 = "https://shilohbridal.pmpframe.com/api/upload/Train/t4.svg";
const t5 = "https://shilohbridal.pmpframe.com/api/upload/Train/t5.svg";
const t6 = "https://shilohbridal.pmpframe.com/api/upload/Train/t6.svg";
const t7 = "https://shilohbridal.pmpframe.com/api/upload/Train/t7.svg";
const t8 = "https://shilohbridal.pmpframe.com/api/upload/Train/t8.svg";

const heart = "https://shilohbridal.pmpframe.com/api/upload/heart.png";

export default function ClotheMenu() {
  const [openMenus, setOpenMenus] = useState({});
  const [hoveredCard, setHoveredCard] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wishlist, setWishlist] = useState(new Set()); // Set to track wishlist items
  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      const formData = new FormData();
      formData.append("action", "productlist");
      formData.append("category", "Shop By Occasion");
      formData.append("subcategory", "Formal Gown");

      const response = await fetch(
        "https://shilohbridal.pmpframe.com/api/product.php",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      console.log("API Response:", data);
      if (data.success) {
        setProducts(data.data);
        console.log("Fetched products:", data.data);
      } else {
        console.error("Invalid response:", data);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleMenuToggle = (index) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const addToWishlist = async (itemCode) => {
    const userId = "110";

    try {
      const formData = new FormData();
      formData.append("action", "wishlist_add");
      formData.append("userid", userId);
      formData.append("itemcode", itemCode);

      const response = await fetch(
        "https://shilohbridal.pmpframe.com/api/wishlist.php",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Wishlist API Response:", data);
      if (data.success) {
        alert("Added to Wishlist!");
        navigate("/Wishlist");
        setWishlist((prev) => new Set(prev).add(itemCode)); // Update the wishlist state
      } else {
        alert(`Error: ${data.message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Error adding to Wishlist:", error);
      alert(`Error: ${error.message}`);
    }
  };

  const isInWishlist = (itemCode) => {
    return wishlist.has(itemCode);
  };

  const StarRating = ({ rating }) => {
    const totalStar = 5;
    const fullStar = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <div className="Star" style={{ display: "flex", alignItems: "center" }}>
        {[...Array(fullStar)].map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStar && <FaStarHalfAlt />}
        {[...Array(totalStar - fullStar - (halfStar ? 1 : 0))].map(
          (_, index) => (
            <FaRegStar key={index} />
          )
        )}
        <p
          style={{
            fontSize: "larger",
            color: "#121212",
            marginLeft: "5px",
            marginBottom: "0px",
          }}
        >
          ( 18 )
        </p>
      </div>
    );
  };

  if (loading) {
    return <div>Loading...</div>; // Loading state
  }

  return (
    <div className="container-clothe mb-5 mt-0">
      <br />
      <br />
      <div className="row">
        {/* Left Side: Filter Sidebar */}
        <div className="col-md-3">
          <div className="category-menu">
            <h2>Filter</h2>
            <ul className="categories">
              {/* Size Filter */}
              <li
                className="list-group-item"
                onClick={() => handleMenuToggle(1)}
              >
                <span>
                  Size
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={openMenus[1] ? "rotate" : ""}
                    style={{ marginLeft: "4px" }}
                  />
                </span>
                {openMenus[1] && (
                  <ul className="dropdown-items">
                    <li>
                      <a href="/">Small</a>
                    </li>
                    <li>
                      <a href="/">Medium</a>
                    </li>
                    <li>
                      <a href="/">Large</a>
                    </li>
                    <li>
                      <a href="/">X-Large</a>
                    </li>
                  </ul>
                )}
              </li>

              {/* Price Filter */}
              <li
                className="list-group-item"
                onClick={() => handleMenuToggle(2)}
              >
                <span>
                  Price
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={openMenus[2] ? "rotate" : ""}
                    style={{ marginLeft: "4px" }}
                  />
                </span>
                {openMenus[2] && (
                  <ul className="dropdown-items">
                    <li>
                      <a href="/">Under Rs.2000</a>
                    </li>
                    <li>
                      <a href="/">Rs.2000 - Rs.4000</a>
                    </li>
                    <li>
                      <a href="/">Rs.4000 - Rs.6000</a>
                    </li>
                    <li>
                      <a href="/">Above Rs.6000</a>
                    </li>
                  </ul>
                )}
              </li>

              {/* Color Filter */}
              <li
                className="list-group-item"
                onClick={() => handleMenuToggle(3)}
              >
                <span>
                  Color
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={openMenus[3] ? "rotate" : ""}
                    style={{ marginLeft: "4px" }}
                  />
                </span>
                {openMenus[3] && (
                  <ul className="dropdown-items">
                    <li>
                      <a href="/">Black</a>
                    </li>
                    <li>
                      <a href="/">Red</a>
                    </li>
                    <li>
                      <a href="/">Teal</a>
                    </li>
                    <li>
                      <a href="/">White</a>
                    </li>
                  </ul>
                )}
              </li>

              {/* Silhouette Filter */}
              <li
                className="list-group-item"
                onClick={() => handleMenuToggle(4)}
              >
                <span>
                  Silhouette
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={openMenus[4] ? "rotate" : ""}
                    style={{ marginLeft: "4px" }}
                  />
                </span>
                {openMenus[4] && (
                  <div className="dropdown-items">
                    {/* Row of 3 images */}
                    <div className="row">
                      <div className="col-4">
                        <a href="/">
                          <img src={s1} alt="s1-images" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-4">
                        <a href="/">
                          <img
                            src={s2}
                            alt="Red Silhouette"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                      <div className="col-4">
                        <a href="/">
                          <img
                            src={s3}
                            alt="Teal Silhouette"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                    {/* Second row of 3 images */}
                    <div className="row mt-3">
                      <div className="col-4">
                        <a href="/">
                          <img src={s4} alt="s4-images" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-4">
                        <a href="/">
                          <img
                            src={s5}
                            alt="Red Silhouette"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                      <div className="col-4">
                        <a href="/">
                          <img src={s6} alt="s6" className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </li>

              {/* Fabric Filter */}
              <li
                className="list-group-item"
                onClick={() => handleMenuToggle(5)}
              >
                <span>
                  Fabric
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={openMenus[5] ? "rotate" : ""}
                    style={{ marginLeft: "4px" }}
                  />
                </span>
                {openMenus[5] && (
                  <ul className="dropdown-items">
                    <li>
                      <a href="/">Silk</a>
                    </li>
                    <li>
                      <a href="/">Georgette</a>
                    </li>
                    <li>
                      <a href="/">Net</a>
                    </li>
                  </ul>
                )}
              </li>

              {/* Train Filter */}
              <li
                className="list-group-item"
                onClick={() => handleMenuToggle(6)}
              >
                <span>
                  Train
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={openMenus[6] ? "rotate" : ""}
                    style={{ marginLeft: "4px" }}
                  />
                </span>
                {openMenus[6] && (
                  <div className="dropdown-items">
                    {/* Row of 4 images */}
                    <div className="row">
                      <div className="col-3">
                        <a href="/">
                          <img src={t1} alt="t1" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-3">
                        <a href="/">
                          <img src={t2} alt="t2" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-3">
                        <a href="/">
                          <img src={t3} alt="t3" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-3">
                        <a href="/">
                          <img src={t4} alt="t4" className="img-fluid" />
                        </a>
                      </div>
                    </div>
                    {/* Second row of 4 images */}
                    <div className="row mt-3">
                      <div className="col-3">
                        <a href="/">
                          <img src={t5} alt="t5" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-3">
                        <a href="/">
                          <img src={t6} alt="t6" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-3">
                        <a href="/">
                          <img src={t7} alt="t7" className="img-fluid" />
                        </a>
                      </div>
                      <div className="col-3">
                        <a href="/">
                          <img src={t8} alt="t8" className="img-fluid" />
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>

        {/* Right Side: Product Display */}
        <div className="col-md-9">
          {/* <h1 className="text-center">Shop By Occasion</h1> */}
          <div className="row">
            {products.map((product) => (
              <div
                className={`col-md-4 mb-4 product-card ${
                  hoveredCard === product.id ? "hovered" : ""
                }`}
                onMouseEnter={() => setHoveredCard(product.id)}
                onMouseLeave={() => setHoveredCard(null)}
                key={product.id}
              >
                <div className="card">
                  {/* Link wraps around the entire card to make it clickable */}
                  <Link
                    to={`/Product`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <div className="image-container">
                      <img
                        src={
                          hoveredCard === product.id
                            ? product.url1
                            : product.url
                        }
                        alt={product.name}
                        className="card-img-top"
                      />
                      {/* <FontAwesomeIcon 
                        icon={faHeart} 
                        className="heart-icon" 
                        onClick={(e) => {
                          e.stopPropagation(); 
                          e.preventDefault(); 
                          navigate('/Wishlist'); 
                        }}
                      /> */}
                      <img
                        src={heart}
                        className="heart-icon"
                        style={{
                          color: isInWishlist(product.itemcode)
                            ? "red"
                            : "gray",
                        }} // Change color based on wishlist status
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          addToWishlist(product.itemcode);
                        }}
                      />
                    </div>
                    <div className="dress-card-body">
                      <h4 className="dress-card-title">{product.name}</h4>

                      <p className="dress-card-para-price">
                        <strike className="original-price">
                          RS. {product.price}
                        </strike>
                        <span className="dress-card-price">
                          {" "}
                          &ensp; Rs. {product.discount}
                        </span>
                      </p>
                      <StarRating rating={4.5} />
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
