import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './nav.css'; 
import { FaSearch } from 'react-icons/fa';
import { FaUser, FaHeart, FaShoppingCart } from 'react-icons/fa';


export const base_url = "https://shilohbridal.pmpframe.com/api/upload/";
const logo = base_url + 'logo.svg'; 
const india = base_url + 'india.svg';
const heart = base_url + 'heart.png';
const user = base_url + 'user.png';
const shopping = base_url + 'shopping-cart.png';

const Icon = ({ icon: IconComponent, onClick }) => {
  return (
    <div className="icon" onClick={onClick}>
      <IconComponent />
    </div>
  );
};

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log('Search Term:', searchTerm);
  };

  const handleCart = () => {
    navigate('/mycart');
  };

  const handleLogin = () => {
    navigate('/Singin');
  };
  
  const handleHomepage = () => {
    navigate('/');
  };

  // Navigate to the wishlist page
  const handleWishlist = () => {
    navigate('/wishlist');
  };

  return (
    <div className="navbar">
      <img src={logo} className="shiloh-logo" alt="logo-image" onClick={handleHomepage} />
      <form onSubmit={handleSearchSubmit} className="search-container">
        <input
          type="text"
          placeholder="Search"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <FaSearch className="search-icon" onClick={handleSearchSubmit} />
      </form>
      <div className="icon-container">
        <img src={india} className="india-logo" alt="india image" />
        <h6>EN | INR</h6>
        
        {/* Navigate to wishlist when the heart icon is clicked */}
        <img src={heart} alt="heart icon" style={{width: '24px', marginLeft: '24px', cursor: 'pointer'}} onClick={handleWishlist} />
        <img src={user} alt="user icon" style={{width: '24px', marginLeft: '24px', cursor: 'pointer'}}  onClick={handleLogin}/>
        <img src={shopping} alt="shopping icon" style={{width: '24px', marginLeft: '24px', cursor: 'pointer'}} onClick={handleCart} />
      </div>
    </div>
  );
};

export default Navbar;
