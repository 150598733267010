import React from 'react';
import Login from '../components/Login/Login';

export default function Singin() {
  return (
    <div>
      <Login />
    </div>
  )
}
