import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import './Hero.css';
// import Background from '../Background/Background';
// import banner from '../../assets/banner.svg';

const Hero = ({ title, subtitle }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleDropdown = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    // <div className="hero-container">
    
      <div className="heading-container">
        <div className="dropdown">
          <h2 onClick={() => toggleDropdown(0)}>
            Bridal Collection
            <FontAwesomeIcon
              icon={faAngleDown}
              className={activeIndex === 0 ? 'rotate' : ''}
            />
          </h2>
          {activeIndex === 0 && (
            <ul className="dropdown-menu">
              <li>Option 1</li>
              <li>Option 2</li>
              <li>Option 3</li>
            </ul>
          )}
        </div>
        <div className="dropdown">
          <h2 onClick={() => toggleDropdown(1)}>
            Bride Maids Collections
            <FontAwesomeIcon
              icon={faAngleDown}
              className={activeIndex === 1 ? 'rotate' : ''}
            />
          </h2>
          {activeIndex === 1 && (
            <ul className="dropdown-menu">
              <li>Option 1</li>
              <li>Option 2</li>
              <li>Option 3</li>
            </ul>
          )}
        </div>
        <div className="dropdown">
          {/* <Link to="/ShopByCategories" style={{textDecoration: 'none', color: 'black'}}> */}
            <h2 onClick={() => toggleDropdown(2)} >
              Shop By Occasion 
              <FontAwesomeIcon
                icon={faAngleDown}
                className={activeIndex === 2 ? 'rotate' : ''}
              />
            </h2>
          {/* </Link> */}
          {activeIndex === 2 && (
            <ul className="dropdown-menu">
              <li><Link to="/ShopByCategories" style={{textDecoration: 'none', color: 'black'}}>Formal Dress</Link></li>
              <li>Cocktail Dress</li>
              <li>Evening Dress</li>
              <li>Veils</li>
            </ul>
          )}
        </div>
        <h2>Special Costumes</h2>
        <h2>Contact Us</h2>
        {/* <img src={banner} alt="Logo" className="Logo" /> */}
      </div>
    // </div>
  );
};

export default Hero;
