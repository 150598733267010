import React, { useState } from "react";
import './Quantity.css';

const QtyInput = () => {
  const [qty, setQty] = useState(1);
  const qtyMin = 1;
  const qtyMax = 100;

  const handleChange = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value) || value <= qtyMin) {
      setQty(qtyMin);
    } else if (value >= qtyMax) {
      setQty(qtyMax);
    } else {
      setQty(value);
    }
  };

  const handleClick = (operator) => {
    let newQty = qty;
    if (operator === "add") {
      newQty = qty < qtyMax ? qty + 1 : qtyMax;
    } else {
      newQty = qty > qtyMin ? qty - 1 : qtyMin;
    }
    setQty(newQty);
  };

  return (
    <div className="qty-input">
      <button
        className="qty-count qty-count--minus"
        data-action="minus"
        onClick={() => handleClick("minus")}
        disabled={qty <= qtyMin}
      >
        -
      </button>
      <input
        className="product-qty"
        type="number"
        name="product-qty"
        min={qtyMin}
        max={qtyMax}
        value={qty}
        onChange={handleChange}
      />
      <button
        className="qty-count qty-count--add"
        data-action="add"
        onClick={() => handleClick("add")}
        disabled={qty >= qtyMax}
      >
        +
      </button>
    </div>
  );
};

export default QtyInput;

