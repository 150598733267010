import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Footer.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa';

// Reusable SectionHeader component
const SectionHeader = ({ title }) => {
    return <h4>{title}</h4>;
};

// Reusable List component
const List = ({ items }) => {
    return (
        <ul>
            {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
};

// Footer Component
const Footer = () => {
    // Dynamic image data
    const logo = 'https://shilohbridal.pmpframe.com/api/upload/logo.svg';
    const socialImages = [
        {
            src: 'https://shilohbridal.pmpframe.com/api/upload/facebook.svg',
            alt: 'Facebook',
            href: 'https://www.facebook.com/' // Add actual link here
        },
        {
            src: 'https://shilohbridal.pmpframe.com/api/upload/instagram.svg',
            alt: 'Instagram',
            href: 'https://www.instagram.com/' // Add actual link here
        },
        {
            src: 'https://shilohbridal.pmpframe.com/api/upload/whatsapp.svg',
            alt: 'WhatsApp',
            href: 'https://wa.me/18001234567' // Add actual link here
        }
    ];

    return (
        <div className="footer-container">
            <img src={logo} className="footerimage" alt="footer-img" />
            <div className="footer-bottom">
                <div className="footer-section">
                    <SectionHeader title="About Us" />
                    <List
                        items={[
                            'Blogs',
                            'Collection',
                            'Site Map',
                            'Book',
                            'Appointment',
                        ]}
                    />
                </div>

                <div className="footer-section">
                    <SectionHeader title="Terms & Policies" />
                    <List
                        items={[
                            'Terms & Conditions',
                            'Cookie Policy',
                            'Return Policy',
                            'Exchange Policy',
                            'Refund Policy',
                        ]}
                    />
                </div>

                <div className="footer-section">
                    <SectionHeader title="Help & Support" />
                    <List items={['Order Tracking', "FAQ's"]} />
                </div>

                <div className="footer-section">
                    <SectionHeader title="Follow Us On" />
                    <div className="social-icons">
                        {socialImages.map((social, index) => (
                            <a key={index} href={social.href} target="_blank" rel="noopener noreferrer">
                                <img src={social.src} className="iconimages" alt={social.alt} />
                            </a>
                        ))}
                    </div>
                    <SectionHeader title="Contact Us" style={{ marginTop: '26px' }} />
                    <List items={[
                        <span><FaPhone style={{ transform: 'rotate(90deg)' }} /> 1800 123 4567</span>,
                        <span><FaEnvelope /> shilohbridals@gmail.com</span>
                    ]} />
                </div>
            </div>
        </div>
    );
};

export default Footer;
