import React, { useState, useEffect } from 'react';
import './Background.css';

export default function Background() {
  const [banner, setBanner] = useState(null); // State to hold the image URL
  const [error, setError] = useState(null);   // State to hold any error message

  useEffect(() => {
    const fetchBannerImage = async () => {
      try {
        const response = await fetch('https://shilohbridal.pmpframe.com/api/upload/banner.svg');
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const imageUrl = response.url; // The URL of the image from the response
        setBanner(imageUrl); // Set the image URL to state
      } catch (error) {
        console.error('Error fetching image:', error);
        setError('Failed to fetch image');
      }
    };

    fetchBannerImage();
  }, []);

  return (
    <div className="container-image">
      {error ? (
        <p className="text-danger">{error}</p> // Display error message if any
      ) : (
        banner && <img src={banner} alt="Banner" className="banner-image" /> // Render image if it exists
      )}
    </div>
  );
}
