import React, { useEffect, useState } from 'react';
import '../ProductDetail/ProductDetails.css';
import Related from '../ClotheMenu/Related';
import SizeGuide from '../SizeGuide/SizeGuide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import QtyInput from '../Quantity/Quantity';

const custom = 'https://shilohbridal.pmpframe.com/api/upload/custom_measurment/custom measurements.svg';

export default function ProductDetails() {
  const [productData, setProductData] = useState(null);
  const [mainImage, setMainImage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [customMeasurement, setCustomMeasurement] = useState(false);
  const [formData, setFormData] = useState({
    bust: '',
    waist: '',
    hips: '',
    height: '',
  });

  const handleImageClick = (imageSrc) => {
    setMainImage(imageSrc);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleQuantityChange = (value) => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity + value));
  };

  const handleCustomMeasurementChange = (e) => {
    setCustomMeasurement(e.target.checked);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchProductDetails = async () => {
    try {
      const formDataObj = new FormData();
      formDataObj.append('action', 'product_detail_list');
      formDataObj.append('id', 863);  // Example product ID
  
      const response = await fetch('https://shilohbridal.pmpframe.com/api/product.php', {
        method: 'POST',
        body: formDataObj,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('API Response:', result);
  
      if (result.success) {
        setProductData(result.data[0]);
        setMainImage(result.data[0].url);
      } else {
        console.error('API call failed:', result.message);
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const formDataObj = new FormData();
      formDataObj.append('action', 'cart_add');
      formDataObj.append('user_id', 110); // Example user ID
      formDataObj.append('itemcode', productData.itemcode);
      formDataObj.append('quantity', quantity);

      if (customMeasurement) {
        formDataObj.append('bust', formData.bust);
        formDataObj.append('waist', formData.waist);
        formDataObj.append('hips', formData.hips);
        formDataObj.append('height', formData.height);
      }

      const response = await fetch('https://shilohbridal.pmpframe.com/api/cart.php', {
        method: 'POST',
        body: formDataObj,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log('Add to Cart Response:', result);

      if (result.success) {
        alert('Item added to cart successfully!');
      } else {
        alert(`Failed to add item to cart: ${result.message}`);
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, []);

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container my-5">
      <div className="row details-snippet1">
        <div className="col-md-5">
          <div className="row">
            <div className="col-md-3 mini-preview">
              <img className="img-fluid" src={productData.url} alt="preview" onClick={() => handleImageClick(productData.url)} />
              <img className="img-fluid" src={productData.url1} alt="preview" onClick={() => handleImageClick(productData.url1)} />
            </div>
            <div className="col-md-9">
              <div className="product-image">
                <img className="img-fluid" src={mainImage} alt="Main" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-7">
          <div className="title">{productData.name}</div>
          <div className="product-code">#{productData.itemcode}</div>
          <div className="rating my-2">
            <span className="filled-star">★</span>
            <span className="filled-star">★</span>
            <span className="filled-star">★</span>
            <span className="filled-star">★</span>
            <span className="unfilled-star">☆</span>
            <span className="rating-count">(18)</span>
          </div>
          <div className="price my-2">
            <strike className="original-price">RS. {parseInt(productData.price) + parseInt(productData.discount)}</strike> RS. {productData.price}
          </div>
          <div className="color-text my-2">
            Color: <span className="brown-text">{productData.color || 'Black'}</span>
          </div>
          <div className="color-circles my-2">
            <span className="color-circle" style={{ backgroundColor: 'black' }}></span>
          </div>

          <div className="form-group my-2 d-flex">
            <label htmlFor="sizeDropdown" className="title-size">Size:</label>
            
            <div className="size-guide ml-2" style={{ fontSize: '12px', cursor: 'pointer', textDecoration: 'underline' }} onClick={toggleModal}>
              Size Guide
            </div>
            </div>

            <div className="form-group-size my-2">
              
              <select id="sizeDropdown" className="form-select" placeholder="Please select ">
                <option selected>Please Selected</option>
                <option>US 0 / UK 2 / EU 30</option>
                <option>US 2 / UK 6 / EU 32</option>
                <option>US 4 / UK 8 / EU 34</option>
                <option>US 6 / UK 10 / EU 36</option>
                <option>US 8 / UK 12/ EU 38</option>
                <option>US 10 / UK 14 / EU 40</option>
                <option>US 12 / UK 16 / EU 42</option>
                <option>US 14 / UK 18 / EU 44</option>
                <option>US 16 / UK 20 / EU 46</option>
                <option>US 16W / UK 20 / EU 46</option>
                <option>US 18 / UK 22 / EU 48</option>
                <option>US 20 / UK 24 / EU 50</option>
              </select>
             
            </div>
  
          <div className="d-flex align-items-center my-2 quantity-selector">
            <label className="title-size mr-2">Quantity: </label>
            <div className="input-group">
          
                        <QtyInput />
                    
            </div>
          </div>

          <div className="form-group my-3">
            <label className="custom-checkbox">
              <input type="checkbox" checked={customMeasurement} onChange={handleCustomMeasurementChange} />
              <span className="checkmark"></span>
              Custom Measurement
            </label>
          </div>

          {customMeasurement && (
            <div className="custom-measurement-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
              <div className="custom-measurement-form" style={{ flex: '1', paddingRight: '20px' }}>
                <h6>Fill in your details to get your item tailor-made for you. Please note a INR Rs. 20 fee applies.</h6>

                <div className="form-group">
                  <label htmlFor="chestMeasurement">Bust (Inch)</label>
                  <select className="form-select" name="bust" value={formData.bust} onChange={handleFormChange}>
                    <option value="">Select Bust</option>
                    <option value="32">32</option>
                    <option value="34">34</option>
                    <option value="36">36</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="waistMeasurement">Waist (Inch)</label>
                  <select className="form-select" name="waist" value={formData.waist} onChange={handleFormChange}>
                    <option value="">Select Waist</option>
                    <option value="24">24</option>
                    <option value="26">26</option>
                    <option value="28">28</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="hipsMeasurement">Hips (Inch)</label>
                  <select className="form-select" name="hips" value={formData.hips} onChange={handleFormChange}>
                    <option value="">Select Hips</option>
                    <option value="34">34</option>
                    <option value="36">36</option>
                    <option value="38">38</option>
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="heightMeasurement">Height (Inch)</label>
                  <select className="form-select" name="height" value={formData.height} onChange={handleFormChange}>
                    <option value="">Select Height</option>
                    <option value="5">5 ft</option>
                    <option value="5.5">5 ft 5 in</option>
                    <option value="6">6 ft</option>
                  </select>
                </div>
              </div>

              <div className="custom-measurement-image" style={{ flex: '1', textAlign: 'center' }}>
                <img src={custom} alt="Tailor Image" style={{ maxWidth: '100%', height: 'auto' }} />
              </div>
            </div>
          )}

          <div className="d-flex align-items-center my-2">
            <button
              className="btn btn addBtn"
              style={{ width: '150px', height: '40px', fontSize: '16px', fontWeight: 'bold', border: '1px solid #8E1336' }}
              onClick={handleSubmit}
            >
              Add to Cart
            </button>
            <button className="wishlist-btn" style={{ marginLeft: '10px', border: 'none', backgroundColor: '#ffffff' }}>
              <FontAwesomeIcon icon={faHeart} style={{ fontSize: '24px', color: '#8E1336' }} />
            </button>
          </div>
          <div className="pincode mt-2">
            <h6 style={{fontSize: '12px'}}>Enter Pincode</h6>
          </div>

          <button className="btn addBtn btn-block mb-2" style={{ backgroundColor: 'white', color: 'black', borderColor: 'black', width: '284px', height: '37px', fontSize: '16px' }}>
          <FontAwesomeIcon icon={faLocationDot}/>Deliver To Xyz - India 012345
          </button>
          <div className="delivery-description my-2">Delivery by: Sunday, 01 October</div>


          <div className="headings-container">

          <div className="heading-section">
              <h4 className="heading-title">Product Details</h4>
              <p className="heading-description">Product Description:</p>
              <p className="heading-description" style={{ marginTop: '35px' }}>Material & Care:</p>
            </div>

            <div className="heading-section">
              <h4 className="heading-title">Size & Fit</h4>
              <p className="heading-description">{productData.description}</p>
              <p className="heading-description" style={{ marginTop: '35px' }}>Material & Care: Soft Net, Dry Clean Only</p>
            </div>
          </div>
        </div>
      </div>

      <div className="related-products details-snippet1">
        <div className="related-heading theme-text">Related Products</div>
        <div className="row">
          <Related />
        </div>
      </div>

      {isModalOpen && <SizeGuide closeModal={toggleModal} />}
    </div>
  );
}
