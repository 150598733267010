import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

export default function OTP() {
  const navigate = useNavigate(); // Initialize the navigate hook

  const logo = "https://shilohbridal.pmpframe.com/api/upload/logo.svg";

  // References to the four input fields
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);

  // Function to handle input focus and value
  const handleInput = (e, currentInput, nextInput) => {
    const value = e.target.value;
    if (value.length === 1 && nextInput) {
      nextInput.current.focus(); // Move focus to the next input
    } else if (value.length === 0 && currentInput) {
      currentInput.current.focus(); // Backspace behavior
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // You can add form validation logic here or an API call to verify the email/phone
    navigate('/NewPassword'); // Redirect to the OTP page
  }

  return (
    <div className="container-forgotpassword">
      <div className="row">
        <div className="col-lg-12 align-items-center justify-content-end">
          <div className="form-2-wrapper">
            <div className="logo text-center">
              <img src={logo} className="logo-image" alt="Shiloh Bridal Logo" />
            </div>
            <h2 className="text-center mb-4">Submit Your Code</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-3 form-box text-center">
                <label className="text-forgot" style={{ marginBottom: '16px' , marginRight: '77px'}}>
                  Please Enter The 4-Digit Code Sent To Your Email Address
                </label>
                <div className="otp-input-wrapper">
                  <input
                    type="text"
                    className="otp-input"
                    maxLength="1"
                    ref={input1}
                    onInput={(e) => handleInput(e, null, input2)}
                  />
                  <input
                    type="text"
                    className="otp-input"
                    maxLength="1"
                    ref={input2}
                    onInput={(e) => handleInput(e, input1, input3)}
                  />
                  <input
                    type="text"
                    className="otp-input"
                    maxLength="1"
                    ref={input3}
                    onInput={(e) => handleInput(e, input2, input4)}
                  />
                  <input
                    type="text"
                    className="otp-input"
                    maxLength="1"
                    ref={input4}
                    onInput={(e) => handleInput(e, input3, null)}
                  />
                </div>
              </div>
              <label className="form-check-label" htmlFor="rememberMe">
                Resend Code
              </label>
              <button type="submit" className="btn btn-outline-secondary login-btn w-100 mb-3">
                Submit
              </button>
              <p className="text-left register-test mt-3">
                Change Email Or Phone Number
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
