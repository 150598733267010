import React from 'react';
import NavBar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import Collection from '../components/Collections/Collection';
import  ShopByCategories from '../components/ShopByCategories/ShopByCategories';
import  Customer from '../components/Customers/Customer';
import  Gallery from '../components/Gallery/Gallery';
import Footer from '../components/Footer/Footer';
import Banner from '../components/Banner/Banner';
import NavHeading from '../components/Navheading/NavHeading';
import ContactHeading from '../components/ContactHeading/ContactHeading';
import Background from '../components/Background/Background';
import Blog from '../components/Blog/Blog';
import Newsletter from '../components/Newsletter/Newsletter';


export default function Home() {
  return (
    <div style={{maxWidth:"100%",}}>
      <NavHeading />
     <NavBar />
     <Hero /> 
     <ContactHeading />
     <Background />
     <Collection/>
     <ShopByCategories />
     <Customer />
     <Blog />
     <Newsletter />
     <Gallery />
     <Footer />
     <Banner />    
    </div>
  )
}
