import React from 'react';
import './ContactHeading.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa'; 

export default function ContactHeading() {
  return (
    <div className="navbar-contact">
      <div className="contact-container">
        <h5><FaPhone style={{ transform: 'rotate(100deg)' , marginRight: '4px'}} /> Toll-Free No: +123456789</h5>
        <h5><FaEnvelope style={{marginRight: '9px'}}/> shilohbridal@gmail.com</h5>
      </div>
    </div>
  );
}
