import Footer from '../Footer/Footer';
import Banner from '../Banner/Banner';
import NavHeading from '../Navheading/NavHeading';
import Hero from '../Hero/Hero';
import NavBar from '../Navbar/Navbar';
import '../ClotheMenu/ClotheMenu.css';
import './Wishlist.css';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

export default function Related() {
  const [hoveredCard, setHoveredCard] = useState(null); 
  const [cards, setCards] = useState([]); 

  // Fetch wishlist data from API using FormData
  useEffect(() => {
    const fetchWishlist = async () => {
      const formData = new FormData();
      formData.append('action', 'wishlist_list');
      formData.append('userid', 110); // Replace with actual user ID

      const response = await fetch('https://shilohbridal.pmpframe.com/api/wishlist.php', {
        method: 'POST',
        body: formData,
      });
      
      const result = await response.json();
      
      if (result.success) {
        const formattedCards = result.data.map(item => ({
          img: item.url,
          hoverImg: item.url1 || item.url, // Use hoverImg if available, fallback to main img
          title: item.name,
          price: `Rs.${item.price}`,
          rating: 4, // Static rating or from API
          discount: item.discount,
        }));

        setCards(formattedCards);
      }
    };

    fetchWishlist();
  }, []);

  const StarRating = ({ rating }) => {
    const totalStar = 5;
    const fullStar = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <div className="Star" style={{display: 'flex', textAlign: 'center', alignItem: 'center', marginLeft: '56px'}}>
        {[...Array(fullStar)].map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStar && <FaStarHalfAlt />}
        {[...Array(totalStar - fullStar - (halfStar ? 1 : 0))].map(
          (_, index) => (
            <FaRegStar key={index} />
          )
        )}
        <p
          style={{
            fontSize: "larger",
            color: "#121212",
            marginLeft: "5px",
            marginBottom: "0px",
          }}
        >
          ( 18 )
        </p>
      </div>
    );
  };

  return (
    <div>
      <NavHeading />
      <NavBar />
      <Hero />

      <div className="col-md-10" style={{ padding: '117px' }}>
        <h2 className='wishlist-heading'>My Wishlist</h2>
        <div className="row">
          {cards.map((item, index) => (
            <div className="col-md-3" key={index}>
              <div className="dress-card">
                <div
                  className="dress-card-head"
                  onMouseEnter={() => setHoveredCard(index)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  {/* Image switching logic */}
                  <img
                    className="dress-card-img-top"
                    src={hoveredCard === index ? item.hoverImg : item.img}
                    alt={item.title}
                    style={{ transition: 'opacity 0.3s ease' }}
                  />

                  {/* Heart icon */}
                  <FontAwesomeIcon icon={faHeart} className="heart-icon" style={{ color: 'red' }} />
                </div>
                
                {/* Always visible details below the image */}
                <div className="dress-card-body">
                  <h4 className="dress-card-title">
                    <Link to="/Product">{item.title}</Link>
                  </h4>
                  <p className="dress-card-para">
                    <strike className="original-price">RS. {item.discount}</strike>
                    <span className="dress-card-price">&ensp; {item.price}</span>
                  </p>
                  <StarRating rating={item.rating} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
      <Banner />
    </div>
  );
}
