import React, { useState, useEffect } from 'react';
import '../Cart/Cart.css';
import SizeGuide from '../SizeGuide/SizeGuide';
import QtyInput from '../Quantity/Quantity';

const black = 'https://shilohbridal.pmpframe.com/api/upload/Black.svg';
const delivery = 'https://shilohbridal.pmpframe.com/api/upload/delivery.png';
const arrow = 'https://shilohbridal.pmpframe.com/api/upload/arrow.png'; 
const uplevel = 'https://shilohbridal.pmpframe.com/api/upload/uplevel.png';
const measure = 'https://shilohbridal.pmpframe.com/api/upload/measure.png';

export default function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [couponCode, setCouponCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sizes = ["US 0 / UK 2 / EU 30", "US 2 / UK 6 / EU 32", "US 4 / UK 8 / EU 34", "US 6 / UK 10 / EU 36", "US 8 / UK 12/ EU 38", "US 10 / UK 14 / EU 40", "US 12 / UK 16 / EU 42", "US 14 / UK 18 / EU 44", "US 16 / UK 20 / EU 46", "US 16W / UK 20 / EU 46" ,"US 18 / UK 22 / EU 48" ,"US 20 / UK 24 / EU 50"];
  const [selectedSize, setSelectedSize] = useState("US 2 / UK 6 / EU 32");

  // Fetch Cart Data from the API using POST method
  useEffect(() => {
    const fetchCartData = async () => {
      try {
        const formData = new FormData();
        formData.append('action', 'cart_list');
        formData.append('user_id', '110');

        const response = await fetch('https://shilohbridal.pmpframe.com/api/cart.php', {
          method: 'POST',
          body: formData,
        });

        const data = await response.json();

        if (data.success) {
          setCartItems(data.cart);
        } else {
          console.error('Failed to fetch cart data:', data.message);
        }
      } catch (error) {
        console.error('Error fetching cart data:', error);
      }
    };

    fetchCartData();
  }, []);

  const handleApplyCoupon = () => {
    console.log("Coupon Code:", couponCode);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSize = (event) => {
    setSelectedSize(event.target.value);
  };

  return (
    <div className="container">
      <h2>My Cart</h2>
      <div className="row">
        {/* Left Section */}
        <div className="col-md-6">
          {/* Delivery Time & Services */}
          <div className="card my-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span>Check delivery time & services</span>
                <form className="form-address w-50">
                  <input className="form-control" type="search" placeholder="Enter Your Address" aria-label="Search" style={{width: '68%', marginLeft: '64px'}} />
                </form>
              </li>
            </ul>
          </div>

          {/* Product Cards */}
          {cartItems.map(item => (
            <div className="card my-3" key={item.id}>
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={black} className="img-fluid rounded-start p-3" alt={item.itemcode} />
                </div>
                <div className="col-md-8 position-relative">
                  <button type="button" className="btn-close position-absolute top-0 end-0 m-2" aria-label="Close"></button>
                  <div className="card-body">
                    <h5 className="card-title">{item.name} <img src={uplevel} alt="uplevel image" style={{marginBottom: '8px', marginLeft: '7px'}}/></h5>
                   
                    <p className="dress-card-para">
                      <strike className="original-price">RS. {item.price} </strike>
                      <span className="dress-card-price"> &ensp; Rs. {item.discount} </span>
                      {/* <span className="dress-card-discount"> {item.discount}</span> */}
                    </p>
                    <p className="card-description" style={{marginTop: '23px', marginBottom: '8px', fontSize: '12px', textDecoration: 'none', cursor: 'pointer'}} onClick={toggleModal}>
                      <img src={measure} alt="size guide" style={{width: '21px', height: '13px', marginRight: '5px'}}/>Size Guide
                    </p>

                    {/* Size Dropdown */}
                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <div className="btn-group" style={{ width: '100px' }}>
                        <label htmlFor="size-select"> Size: </label>
                        <select
                          id="size-select"
                          value={selectedSize}
                          onChange={handleSize}
                          style={{width: '42px', height: '32px', borderRadius: '4px', marginTop: '1px', marginLeft: '12px'}}
                        >
                          {sizes.map(size => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="quantity-selector d-flex align-items-center ms-4">
                        <QtyInput />
                      </div>
                    </div>
                    <p style={{fontSize: '13px', marginTop: '8px'}}><img src={arrow}/> 14 Days return available</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>

        {/* Right Section */}
        <div className="col-md-6">
        <div className="card my-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item" style={{display:'flex', justifyContent:'flex-start', marginRight: '2px', gap: '7px'}}>
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Express"/>
                <img src={delivery} alt="fast delivery" style={{width: '23px'}} />
                <label htmlFor="vehicle1"> Express Delivery</label>
                <p style={{color: '#8E1336', marginBottom: '3px', fontSize: '12px'}}> Know More</p>
              </li>
            </ul>
          </div>

          <div className="card my-3">
            <h5 style={{fontSize: '20px', fontFamily: 'playfair display', fontWeight: 'bolder'}}>Summary ({cartItems.length} Item{cartItems.length > 1 ? 's' : ''})</h5>
            <ul className="list-group-content">
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span>Total MRP</span>
                <span>Rs. 4099</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span>Discount on MRP</span>
                <span>- Rs. 630</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span>Coupon Discount</span>
                <span style={{color: 'brown'}}>Apply Coupon</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span>Shipping Fee</span>
                <span style={{color: 'brown'}}>FREE</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center">
                <span>Express Deliver Fee</span>
              </li>
              <li className="list-group-item d-flex justify-content-between align-items-center" style={{fontWeight: 'bolder', fontSize : '20px', fontFamily: 'playfair display'}}>
                <strong>Total Amount</strong>
                <strong>Rs. 3469</strong>
              </li>
            </ul>
          </div>

          <div className="card my-3" style={{margin: '25px'}}>
            <ul className="list-group ">
              <li className="list-group-item">
                <p>Have a coupon? Enter your code</p>
              </li>
              <div className="row mt-2">
                <div className="col-md-8">
                  <input type="text" className="form-control" placeholder="Coupon Code" value={couponCode} onChange={(e) => setCouponCode(e.target.value)} style={{borderBottom: '4px solid #121212', marginBottom: '14px', border: '0px solid #ffffff', borderRadius: '2px'}}/>
                </div>
                <div className="col-md-4 d-flex justify-content-center">
                  <button className="btn btn coupon-code-button" onClick={handleApplyCoupon}>
                    Apply
                  </button>
                </div>
              </div>
            </ul>
          </div>


          <div className="checkout">
            <button className="btn btn checkout-button" type="button">Continue To Checkout</button>
          </div>
        </div>
      </div>

      {isModalOpen && <SizeGuide closeModal={toggleModal} />}
    </div>
  );
}
