import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../ClotheMenu/ClotheMenu.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FaStar, FaRegStar, FaStarHalfAlt } from "react-icons/fa";

const card1 = 'https://shilohbridal.pmpframe.com/api/upload/b.svg';
const card2 = 'https://shilohbridal.pmpframe.com/api/upload/r.svg';
const card3 = 'https://shilohbridal.pmpframe.com/api/upload/t.svg';
const card4 = 'https://shilohbridal.pmpframe.com/api/upload/w.svg';
const black = 'https://shilohbridal.pmpframe.com/api/upload/hover-black.svg';
const red = 'https://shilohbridal.pmpframe.com/api/upload/hover-red.svg';
const teil = 'https://shilohbridal.pmpframe.com/api/upload/hover-teal.svg';
const white = 'https://shilohbridal.pmpframe.com/api/upload/hover-white.svg';


export default function Related() {
  const [hoveredCard, setHoveredCard] = useState(null); // Track hover state by card index

  const StarRating = ({ rating }) => {
    const totalStar = 5;
    const fullStar = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <div className="Star" style={{ display: "flex", alignItems: "center" }}>
        {[...Array(fullStar)].map((_, index) => (
          <FaStar key={index} />
        ))}
        {halfStar && <FaStarHalfAlt />}
        {[...Array(totalStar - fullStar - (halfStar ? 1 : 0))].map(
          (_, index) => (
            <FaRegStar key={index} />
          )
        )}
        <p
          style={{
            fontSize: "larger",
            color: "#121212",
            marginLeft: "5px",
            marginBottom: "0px",
          }}
        >
          ( 18 )
        </p>
      </div>
    );
  };

  // Define your card data here
  const cards = [
    {
      img: card1,
      hoverImg: black,
      title: "Black Frill Gown",
      price: "Rs.3469",
      rating: 3.5,
    },
    {
      img: card2,
      hoverImg: red,
      title: "Red Cut Net Gown",
      price: "Rs.2489",
      rating: 4.5,
    },
    {
      img: card3,
      hoverImg: teil,
      title: "Teal Fish-Cut Gown",
      price: "Rs.3469",
      rating: 4,
    },
    {
      img: card4,
      hoverImg: white,
      title: "White Net Gown",
      price: "Rs.3469",
      rating: 4,
    },
  ];

  return (
    <div className="col-md-12">
      <div className="row">
        {cards.map((item, index) => (
          <div className="col-md-3" key={index}>
            <div className="dress-card">
              <div
                className="dress-card-head"
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <img
                  className="dress-card-img-top default-img"
                  src={item.img}
                  alt={item.title}
                  style={{
                    display: hoveredCard === index ? "none" : "block",
                  }}
                />
                <img
                  className="dress-card-img-top hover-img"
                  src={item.hoverImg}
                  alt={`${item.title} Hover`}
                  style={{
                    display: hoveredCard === index ? "block" : "none",
                  }}
                />

                {/* Bestseller Button */}
                <button className="bestseller-btn">Bestseller</button>

                <FontAwesomeIcon icon={faHeart} className="heart-icon" />
              </div>
              <div className="dress-card-body">
                <h4 className="dress-card-title">
                  <Link to="/Product">{item.title}</Link>
                </h4>
                <p className="dress-card-para">
                  <strike className="original-price">RS. 3999</strike>
                  <span className="dress-card-price">&ensp; {item.price}</span>
                </p>
                <StarRating rating={item.rating} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
