import React, { useState } from 'react';
import './Customer.css';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

// Reusable CustomerCard component
const CustomerCard = ({ image }) => {
  return (
    <div className="customer-card">
      <img src={image} alt="Customer" />
    </div>
  );
};

const CardName = ({ name }) => {
  return (
    <div className="customer-name">
      <h5>{name}</h5>
    </div>
  );
};

// Reusable StarRating component
const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 !== 0;
  return (
    <div className="stars">
      {[...Array(fullStars)].map((_, index) => (
        <FaStar key={index} />
      ))}
      {halfStar && <FaStarHalfAlt />}
    </div>
  );
};

// Main Customer component
const Customer = () => {
  const customers = [
    { image: 'https://shilohbridal.pmpframe.com/api/upload/Lorem.svg', name: 'Lorem ipsum', rating: 4.5 },
    { image: 'https://shilohbridal.pmpframe.com/api/upload/Lorem2.svg', name: 'Lorem ipsum 2', rating: 5 },
    { image: 'https://shilohbridal.pmpframe.com/api/upload/Lorem3.svg', name: 'Lorem ipsum 3', rating: 4.5 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="testimonials-section">
      <h2>Hear From Our Customers</h2>
      <div className="testimonials-container">
        <div className="customers-list" style={{ transform: `translateX(-${currentIndex * 33.33}%)` }}>
          {customers.map((customer, index) => (
            <div key={index} className="slide">
              <CustomerCard image={customer.image} />
              <StarRating rating={customer.rating} />
              <div className="testimonial-detail">
                <p>Lorem ipsum dolor sit amet consectetur. Magna sit cursus sed tellus mauris. Metus cras molestie sapien in bibendum malesuada.</p>
                <CardName name={customer.name} />
                <p>Married on 01/01/2024</p>
              </div>
            </div>
          ))}
        </div>
        <div className="carousel-buttons">
          {customers.map((_, index) => (
            <button
              key={index}
              className={`carousel-btn ${currentIndex === index ? 'active' : ''}`}
              onClick={() => handleNext(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Customer;
