import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';
    
import './ShopByCategories.css';
const Black = 'https://shilohbridal.pmpframe.com/api/upload/Black.svg';
const Teil = 'https://shilohbridal.pmpframe.com/api/upload/Teil.svg';
const categoryvideo = 'https://shilohbridal.pmpframe.com/api/upload/categoryvideo.svg';

// Reusable CategoryTabs component
// Reusable CategoryTabs component
const CategoryTabs = ({ categories, activeTab, onTabClick }) => {
    return (
        <div className="category-tabs">
            {categories.map((category, index) => (
                <span
                    key={index}
                    className={activeTab === category ? "active-tab" : ""}
                    onClick={() => onTabClick(category)}
                >
                    {category}
                </span>
            ))}
        </div>
    );
};


// Reusable ProductCard component
const ProductCard = ({ image, title }) => {
    return (
        <div className="product-card">
            <img src={image} alt={title} />
            <p>{title}</p>
        </div>
    );
};

// Reusable VideoCard component
const VideoCard = ({ image, title }) => {
    return (
        <div className="video-card">
            <div className="video-wrapper">
                <img src={image} alt={title} />
            </div>
        </div>
    );
};

// Main ShopByCategories component
const ShopByCategories = () => {
    const categories = ['Cocktail Dress', 'Formal Dress', 'Evening Dress', 'Veils'];
    const [activeTab, setActiveTab] = useState(categories[0]);

    const handleTabClick = (category) => {
        setActiveTab(category);
    };

    return (
        <div className="shop-by-categories">
            <h2>Shop By Categories</h2>
            <CategoryTabs 
                categories={categories} 
                activeTab={activeTab} 
                onTabClick={handleTabClick} 
            />
            <div className="product-container">
                <ProductCard image={Black} title="Black Frill Gown" />
                <ProductCard image={Teil} title="Teal Fish-Cut Gown" />
                <VideoCard image={categoryvideo} title="Red Gown Video" />
               
            </div>
            <div className="pagination">
                <button className="prev-button"><faArrowRight /></button>
                <button className="next-button"><FaAngleRight /></button>

            </div>
        </div>
    );
};

export default ShopByCategories;

