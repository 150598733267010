import React from 'react';
import './Banner.css'

function Banner() {
  return (
    <div className="banner">
      <p style={{marginBottom: '0rem'}}>2023silohbridals.com</p>
    </div>
  );
}

export default Banner;